import { TranslationObject } from './en';

export const langPl: TranslationObject = {
  'common.expected_finish': 'Zakładany czas zakończenia',
  'common.minutes': 'min',
  'common.now': 'Teraz',
  'common.next': 'Następnie',
  'common.public_message': 'Wiadomość publiczna',
  'common.scheduled_start': 'Planowany początek',
  'common.scheduled_end': 'Planowany koniec',
  'common.projected_start': 'Przewidywany początek',
  'common.projected_end': 'Przewidywany koniec',
  'common.stage_timer': 'Timer Scena',
  'common.started_at': 'Rozpoczęte o',
  'common.time_now': 'Aktualny czas',
  'common.no_data': 'Brak danych',
  'countdown.ended': 'Zakończone o',
  'countdown.running': 'Trwa',
  'countdown.select_event': 'Wybierz event który chcesz śledzić',
  'countdown.to_start': 'Do rozpoczęcia',
  'countdown.waiting': 'Oczekiwanie na start',
  'countdown.overtime': 'ponad czasem',
  'timeline.live': 'live',
  'timeline.done': 'Zakończony',
  'timeline.due': 'termin',
  'timeline.followedby': 'Następnie',
};
