import { TranslationObject } from './en';

export const langZhCn: TranslationObject = {
  'common.expected_finish': '预计完成',
  'common.minutes': '分钟',
  'common.now': '现在',
  'common.next': '接下来',
  'common.public_message': '公众消息',
  'common.scheduled_start': '计划开始',
  'common.scheduled_end': '计划结束',
  'common.projected_start': '预计开始',
  'common.projected_end': '预计结束',
  'common.stage_timer': '舞台计时器',
  'common.started_at': '开始于',
  'common.time_now': '当前时间',
  'common.no_data': '无数据',
  'countdown.ended': '事件结束于',
  'countdown.running': '事件进行中',
  'countdown.select_event': '选择要关注的事件',
  'countdown.to_start': '到开始时间',
  'countdown.waiting': '等待事件开始',
  'countdown.overtime': '超时了',
  'timeline.live': '当前进行',
  'timeline.done': '已结束',
  'timeline.due': '即将开始',
  'timeline.followedby': '随后是',
};
